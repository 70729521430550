* {
  box-sizing: border-box;
}

ul {
  padding: 0;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  visibility: hidden; /* doesn't seem to work */
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #E0E0E0;
}